<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <v-select
          searchable
          clearable
          class="w-full"
          label="text"
          placeholder="Duration"
          :options="duration"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <v-select
          searchable
          clearable
          label="text"
          class="w-full"
          placeholder="Sort By"
          :options="sort_by"
        ></v-select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
        <!-- <VueHotelDatepicker @confirm="getValue" /> -->
        <date-range-picker
          ref="picker"
          :opens="'center'"
          :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY HH:mm:ss' }"
          :minDate="null"
          :maxDate="null"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="true"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="false"
          v-model="dateRange"
        >
          <div
            slot="input"
            slot-scope="picker"
            style="min-width: 350px;"
          >{{ picker.startDate }} - {{ picker.endDate }}</div>
        </date-range-picker>
      </vs-col>
    </vs-row>

    <vs-collapse accordion :type="type">
      <vs-collapse-item>
        <div slot="header">All Over India</div>
        <vs-table :data="users">
          <template slot="thead">
            <vs-th>Spoc Name</vs-th>
            <vs-th>MWB Count</vs-th>
            <vs-th>Coverage</vs-th>
            <vs-th>Enrollment</vs-th>
            <vs-th>Visitors</vs-th>
            <vs-th>Utilization</vs-th>
            <vs-th>Delays</vs-th>
            <vs-th>Dues</vs-th>
            <vs-th>SR%</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].username">
                <a v-on:click="spoc_name">{{data[indextr].name}}</a>
              </vs-td>

              <vs-td :data="data[indextr].username">{{data[indextr].mwb_count}}</vs-td>

              <vs-td :data="data[indextr].username">{{data[indextr].mwb_count}}</vs-td>

              <vs-td :data="data[indextr].id">{{data[indextr].enrollment}}</vs-td>

              <vs-td :data="data[indextr].id">{{data[indextr].visitors}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].utilization}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].delays}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].due}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].sr}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-collapse-item>
      <vs-collapse-item>
        <div slot="header">Hyderabad</div>
        <vs-table :data="users">
          <template slot="thead">
            <vs-th>Spoc Name</vs-th>
            <vs-th>MWB Count</vs-th>
            <vs-th>Coverage</vs-th>
            <vs-th>Enrollment</vs-th>
            <vs-th>Visitors</vs-th>
            <vs-th>Utilization</vs-th>
            <vs-th>Delays</vs-th>
            <vs-th>Dues</vs-th>
            <vs-th>SR%</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].email">
                <a v-on:click="spoc_name">{{data[indextr].name}}</a>
              </vs-td>

              <vs-td :data="data[indextr].username">{{data[indextr].mwb_count}}</vs-td>

              <vs-td :data="data[indextr].username">{{data[indextr].mwb_count}}</vs-td>

              <vs-td :data="data[indextr].id">{{data[indextr].enrollment}}</vs-td>

              <vs-td :data="data[indextr].id">{{data[indextr].visitors}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].utilization}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].delays}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].due}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].sr}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-collapse-item>
      <vs-collapse-item>
        <div slot="header">Mumbai</div>
        <vs-table :data="users">
          <template slot="thead">
            <vs-th>Spoc Name</vs-th>
            <vs-th>MWB Count</vs-th>
            <vs-th>Coverage</vs-th>
            <vs-th>Enrollment</vs-th>
            <vs-th>Visitors</vs-th>
            <vs-th>Utilization</vs-th>
            <vs-th>Delays</vs-th>
            <vs-th>Dues</vs-th>
            <vs-th>SR%</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].email">
                <a v-on:click="spoc_name">{{data[indextr].name}}</a>
              </vs-td>

              <vs-td :data="data[indextr].username">{{data[indextr].mwb_count}}</vs-td>

              <vs-td :data="data[indextr].username">{{data[indextr].mwb_count}}</vs-td>

              <vs-td :data="data[indextr].id">{{data[indextr].enrollment}}</vs-td>

              <vs-td :data="data[indextr].id">{{data[indextr].visitors}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].utilization}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].delays}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].due}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].sr}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-collapse-item>
      <vs-collapse-item>
        <div slot="header">Bangalore</div>
        <vs-table :data="users">
          <template slot="thead">
            <vs-th>Spoc Name</vs-th>
            <vs-th>MWB Count</vs-th>
            <vs-th>Coverage</vs-th>
            <vs-th>Enrollment</vs-th>
            <vs-th>Visitors</vs-th>
            <vs-th>Utilization</vs-th>
            <vs-th>Delays</vs-th>
            <vs-th>Dues</vs-th>
            <vs-th>SR%</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].email">
                <a v-on:click="spoc_name">{{data[indextr].name}}</a>
              </vs-td>

              <vs-td :data="data[indextr].username">{{data[indextr].mwb_count}}</vs-td>

              <vs-td :data="data[indextr].username">{{data[indextr].mwb_count}}</vs-td>

              <vs-td :data="data[indextr].id">{{data[indextr].enrollment}}</vs-td>

              <vs-td :data="data[indextr].id">{{data[indextr].visitors}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].utilization}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].delays}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].due}}</vs-td>
              <vs-td :data="data[indextr].id">{{data[indextr].sr}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-collapse-item>
    </vs-collapse>

    <!-- <vs-table :data="newdata">
      <template slot="thead">
        <vs-th></vs-th>
        <vs-th></vs-th>
        <vs-th>Hyderabad</vs-th>
        <vs-th>Maria</vs-th>
        <vs-th>Imran</vs-th>
        <vs-th>Ismail</vs-th>
        <vs-th>Anita</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].username">{{data[indextr].level}}</vs-td>

          <vs-td :data="data[indextr].username">{{data[indextr].section}}</vs-td>

          <vs-td :data="data[indextr].email">{{data[indextr].total}}</vs-td>

          <vs-td :data="data[indextr].username">{{data[indextr].Maria}}</vs-td>

          <vs-td :data="data[indextr].username">{{data[indextr].Imran}}</vs-td>

          <vs-td :data="data[indextr].id">{{data[indextr].Ismail}}</vs-td>

          <vs-td :data="data[indextr].id">{{data[indextr].Anita}}</vs-td>
        </vs-tr>
      </template>
    </vs-table>-->
  </div>
</template>


<script>
// import VueHotelDatepicker from "@northwalker/vue-hotel-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";

export default {
  mounted() {
    this.cm2Redirect();
  },
  watch: {
    value: function(value) {
      console.log(value);
    }
  },
  data() {
    return {
      name: "App",
      type: "margin",
      fromDate: null,
      toDate: null,
      select1: "",
      select2: "",
      configFromdateTimePicker: {
        minDate: null,
        maxDate: null
      },
      configTodateTimePicker: {
        minDate: null
      },
      dateRange: "",
      duration: [
        { text: "WTD", value: 0 },
        { text: "MTD", value: 1 },
        { text: "QTD", value: 2 },
        { text: "YTD", value: 3 }
      ],
      sort_by: [
        { text: "MWB Count", value: 0 },
        { text: "Enrollment", value: 1 },
        { text: "Visitors", value: 2 },
        { text: "Utilization", value: 3 },
        { text: "Delays", value: 4 },
        { text: "Due", value: 5 },
        { text: "SR%", value: 6 }
      ],
      users: [
        {
          id: 1,
          name: "Ismail",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-"
        },
        {
          id: 2,
          name: "Nida",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-"
        },
        {
          id: 3,
          name: "Anita",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-"
        },
        {
          id: 4,
          name: "Imran",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-"
        },
        {
          id: 5,
          name: "Jain",
          mwb_count: "30",
          enrollment: "15",
          visitors: "90",
          utilization: "190",
          delays: "30|220",
          due: "-",
          sr: "-"
        }
      ],
      newdata: [
        {
          level: "P1",
          section: "Allocated",
          code: "allocated",
          Maria: 0,
          Imran: 0,
          Ismail: 0,
          Anita: 0
          // total: Maria + Imran + Ismail + Anita
        },
        {
          level: "P1",
          section: "Contacted",
          code: "contacted",
          Maria: 0,
          Imran: 0,
          Ismail: 0,
          Anita: 0
          // total: Maria + Imran + Ismail + Anita
        },
        {
          level: "P1",
          section: "Not Contacted",
          code: "notcontacted",
          Maria: 0,
          Imran: 0,
          Ismail: 0,
          Anita: 0
          // total: Maria + Imran + Ismail + Anita
        },
        {
          level: "P2",
          section: "Allocated",
          code: "allocated",
          Maria: 0,
          Imran: 0,
          Ismail: 0,
          Anita: 0
          // total: Maria + Imran + Ismail + Anita
        },
        {
          level: "P2",
          section: "Contacted",
          code: "contacted",
          Maria: 0,
          Imran: 0,
          Ismail: 0,
          Anita: 0
          // total: Maria + Imran + Ismail + Anita
        },
        {
          level: "P2",
          section: "Not Contacted",
          code: "notcontacted",
          Maria: 0,
          Imran: 0,
          Ismail: 0,
          Anita: 0
          // total: Maria + Imran + Ismail + Anita
        },
        {
          level: "P3",
          section: "Allocated",
          code: "allocated",
          Maria: 0,
          Imran: 0,
          Ismail: 0,
          Anita: 0
          // total: Maria + Imran + Ismail + Anita
        },
        {
          level: "P3",
          section: "Contacted",
          code: "contacted",
          Maria: 0,
          Imran: 0,
          Ismail: 0,
          Anita: 0
          // total: Maria + Imran + Ismail + Anita
        },
        {
          level: "P3",
          section: "Not Contacted",
          code: "notcontacted",
          Maria: 0,
          Imran: 0,
          Ismail: 0,
          Anita: 0
          // total: Maria + Imran + Ismail + Anita
        }
      ]
    };
  },
  methods: {
    updateValues() {
      // console.log("updating value");
      // console.log(this.dateRange);
      console.log(this.datatoTimestamp(this.dateRange.startDate));
      axios
        .get(
          `${
            constants.SERVER_API
          }getMwbCoverage?from_date=${this.datatoTimestamp(
            this.dateRange.startDate
          )}&to_date=${this.datatoTimestamp(this.dateRange.endDate)}`,
          {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }
          }
        )
        .then(response => {
          console.log(response.data.data);
          response.data.data.forEach(spoc => {
            if (spoc.spoc_id !== 38) {
              let P1allocated = 0;
              let P1contacted = 0;
              let P1notcontacted = 0;
              let P2allocated = 0;
              let P2contacted = 0;
              let P2notcontacted = 0;
              let P3allocated = 0;
              let P3contacted = 0;
              let P3notcontacted = 0;
              for (var key in spoc.P1) {
                if (spoc.P1.hasOwnProperty(key)) {
                  P1allocated = P1allocated + spoc.P1[key].allocated;
                  P1contacted = P1contacted + spoc.P1[key].contacted;
                  P1notcontacted = P1notcontacted + spoc.P1[key].not_contacted;
                }
              }
              for (var key2 in spoc.P2) {
                if (spoc.P2.hasOwnProperty(key2)) {
                  P2allocated = P2allocated + spoc.P2[key2].allocated;
                  P2contacted = P2contacted + spoc.P2[key2].contacted;
                  P2notcontacted = P2notcontacted + spoc.P2[key2].not_contacted;
                }
              }
              for (var key3 in spoc.P3) {
                if (spoc.P3.hasOwnProperty(key3)) {
                  P3allocated = P3allocated + spoc.P3[key3].allocated;
                  P3contacted = P3contacted + spoc.P3[key3].contacted;
                  P3notcontacted = P3notcontacted + spoc.P3[key3].not_contacted;
                }
              }
              this.newdata.forEach(data => {
                if (data.level === "P1") {
                  if (data.code === "allocated") {
                    for (var key in data) {
                      if (data.hasOwnProperty(key)) {
                        if (key === spoc.spoc_name) {
                          data[key] = P1allocated;
                        }
                      }
                    }
                  } else if (data.code === "contacted") {
                    for (var key2 in data) {
                      if (data.hasOwnProperty(key2)) {
                        if (key2 === spoc.spoc_name) {
                          data[key2] = P1contacted;
                        }
                      }
                    }
                  } else if (data.code === "notcontacted") {
                    for (var key3 in data) {
                      if (data.hasOwnProperty(key3)) {
                        if (key3 === spoc.spoc_name) {
                          data[key3] = P1notcontacted;
                        }
                      }
                    }
                  }
                } else if (data.level === "P2") {
                  if (data.code === "allocated") {
                    for (var key4 in data) {
                      if (data.hasOwnProperty(key4)) {
                        if (key4 === spoc.spoc_name) {
                          data[key4] = P2allocated;
                        }
                      }
                    }
                  } else if (data.code === "contacted") {
                    for (var key5 in data) {
                      if (data.hasOwnProperty(key5)) {
                        if (key5 === spoc.spoc_name) {
                          data[key5] = P2contacted;
                        }
                      }
                    }
                  } else if (data.code === "notcontacted") {
                    for (var key6 in data) {
                      if (data.hasOwnProperty(key6)) {
                        if (key6 === spoc.spoc_name) {
                          data[key6] = P2notcontacted;
                        }
                      }
                    }
                  }
                } else if (data.level === "P3") {
                  if (data.code === "allocated") {
                    for (var key7 in data) {
                      if (data.hasOwnProperty(key7)) {
                        if (key7 === spoc.spoc_name) {
                          data[key7] = P3allocated;
                        }
                      }
                    }
                  } else if (data.code === "contacted") {
                    for (var key8 in data) {
                      if (data.hasOwnProperty(key8)) {
                        if (key8 === spoc.spoc_name) {
                          data[key8] = P3contacted;
                        }
                      }
                    }
                  } else if (data.code === "notcontacted") {
                    for (var key9 in data) {
                      if (data.hasOwnProperty(key9)) {
                        if (key9 === spoc.spoc_name) {
                          data[key9] = P3notcontacted;
                        }
                      }
                    }
                  }
                }
              });
              console.log("**********");
              console.log(P1allocated);
              console.log(P1contacted);
              console.log(P1notcontacted);
              console.log("**********");

              /* for (var key in spoc.P1) {
                if (spoc.P1.hasOwnProperty(key)) {
                  cityallocatedP1 = cityallocatedP1 + spoc.P1[key].allocated;
                  citycontactedP1 = citycontactedP1 + spoc.P1[key].contacted;
                  citynotcontactedP1 =
                    citynotcontactedP1 + spoc.P1[key].not_contacted;
                }
              }
              for (var key2 in spoc.P2) {
                if (spoc.P2.hasOwnProperty(key2)) {
                  cityallocatedP2 = cityallocatedP2 + spoc.P2[key2].allocated;
                  citycontactedP2 = citycontactedP2 + spoc.P2[key2].contacted;
                  citynotcontactedP2 =
                    citynotcontactedP2 + spoc.P2[key2].not_contacted;
                }
              }
              for (var key3 in spoc.P3) {
                if (spoc.P3.hasOwnProperty(key3)) {
                  cityallocatedP3 = cityallocatedP3 + spoc.P3[key3].allocated;
                  citycontactedP3 = citycontactedP3 + spoc.P3[key3].contacted;
                  citynotcontactedP3 =
                    citynotcontactedP3 + spoc.P3[key3].not_contacted;
                }
              } */
            }
          });
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getValue(value) {
      console.log(value);
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    spoc_name() {
      this.$router.push("SingleBdeStats");
    }
  },
  components: {
    // VueHotelDatepicker,
    DateRangePicker,
    "v-select": vSelect
  }
};
</script>
<style>
.vhd-input[data-v-25b15922] {
  min-width: 250px;
  padding: 5px;
  border: 1px solid #eee;
  color: #505050;
  font-size: 16px;
  line-height: 9px;
  border-radius: 8px;
  border-color: lightgray;
  outline: none;
}
</style>

